.footer {
  background-color: #991e2d;
  color: var(--gray_2);
}

.footerTop {
  padding-block: 60px;
}

.footerTop .container {
  display: grid;
  gap: 60px;
}

.footer .logo {
  font-size: 2.28rem;
  color: var(--white);
  font-weight: var(--fw-700);
  margin-bottom: 10px;
}

.footerText {
  line-height: 2;
  margin-block: 20px 15px;
}

.socialList {
  display: flex;
  gap: 15px;
}

.socialLink {
  border: 1px solid;
  padding: 8px;
  border-radius: 50%;
  transition: var(--transition-1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerListTitle {
  color: var(--white);
  font-size: var(--fs-2);
  font-weight: var(--fw-700);
  margin-block-end: 25px;
}

.footerDetail {
  font-size: var(--fs-6);
  margin-block-start: 16px;
  transition: var(--transition-1);
  display: flex;
  gap: 10px;
}

.icon {
  font-size: var(--fs-4);
}

.footerLink {
  font-size: var(--fs-6);
  margin-block-start: 16px;
  transition: var(--transition-1);
  cursor: pointer;
}

.footerBottom {
  background-color: #8b0d1c;
  padding-block: 20px;
  font-size: var(--fs-7);
  padding-bottom: 50px;
}

.location {
  width: 100%;
  height: 300px;
  position: relative;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.copyright,
.poweredBy {
  text-align: center;
  line-height: 1.7;
}

.poweredBy {
  display: flex;
  gap: 0.5em;
  justify-content: center;
}

@media (min-width: 768px) {
  .footerTop .container {
    grid-template-columns: 1fr 1fr;
  }

  .footerBottom .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .footerTop .container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .footerBottom {
    padding-bottom: 20px;
  }
}
