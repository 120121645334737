.offersItem {
  min-width: 100%;
  margin-inline: 15px;
  scroll-snap-align: start;
}

.offersCard {
  background-color: #fefefe;
  padding: 30px 10px;
  text-align: center;
}

.offersCard .cardBanner {
  margin-block-end: 20px;
}

.offersCard .cardBanner img {
  max-width: max-content;
  margin-inline: auto;
}

.offersCard .cardSubtitle {
  color: var(--primary);
  font-size: var(--fs-7);
  font-weight: var(--fw-700);
  text-transform: uppercase;
  margin-block-end: 5px;
}

.offersCard .cardTitle {
  color: var(--guntmetal-2);
  margin-block-end: 12px;
}

.offersCard .btn {
  max-width: max-content;
  margin-inline: auto;
  font-size: var(--fs-8);
  font-weight: var(--fw-400);
  padding: 12px 25px;
}

@media (min-width: 480px) {
  .offersCard {
    text-align: left;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 30px 40px;
  }

  .offersCard .cardBanner {
    margin-block-end: 0;
    order: 1;
  }

  .offerCard .btn {
    margin-inline: 0;
  }
}

@media (min-width: 769px) {
  .offersList {
    gap: 30px;
  }

  .offersItem {
    min-width: calc(50% - 15px);
    margin-inline: 0;
  }
}

@media (min-width: 992px) {
  .offersCard {
    padding-block: 70px;
  }

  .offersCard .cardTitle {
    font-size: 1.64rem;
  }

  .offersCard .btn {
    font-size: 1.07rem;
    padding: 15px 25px;
  }
}

@media (min-width: 1200px) {
  .offersCard .cardTitle {
    font-size: 2rem;
    margin-block: 20px;
  }
}
