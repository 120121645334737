.product .sectionTitle {
  text-align: left;
}

.productCard {
  background-color: var(--greyish-white);
  padding: 30px 20px;
  text-align: center;
  outline: 2px solid transparent;
  transition: var(--transition-2);
}

.productCard:is(:hover, :focus-within) {
  outline-color: var(--primary);
}

.productCard .cardBanner {
  position: relative;
  max-width: max-content;
  margin-inline: auto;
  margin-block-end: 20px;
}

.productCard .cardBanner img {
  transition: var(--transition-2);
}

.productCard:is(:hover, :focus-within) .cardBanner img {
  opacity: 0.6;
}

.productCard .btnWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 10px;
}

.productCard .productBtn {
  position: relative;
  background-color: var(--primary);
  font-size: 1.42rem;
  color: var(--white);
  padding: 12px;
  border-radius: 50%;
  opacity: 0;
  transition: var(--transition-2);
}

.tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--black_99);
  border-radius: var(--radius-4);
  font-size: var(--fs-7);
  min-width: max-content;
  padding: 4px 12px;
  pointer-events: none;
  opacity: 0;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  border: 5px solid transparent;
  border-bottom-color: var(--black_99);
}

.productCard .productBtn ion-icon {
  --ionicon-stroke-width: 50px;
}

.productCard:is(:hover, :focus-within) .productBtn {
  opacity: 1;
}

.productCard .productBtn:is(:hover, :focus) {
  background-color: var(--blue-black_1);
}

.productCard .productBtn:is(:hover, :focus) .tooltip {
  opacity: 1;
}

.productCard .ratingWrapper {
  margin-block-end: 10px;
}

.productCard .cardTitle {
  color: var(--dark-blue);
  font-size: var(--fs-4);
  font-weight: var(--fw-500);
  margin-block-end: 10px;
}

.productCard .cardTitle > div {
  transition: var(--transition-2);
}

.productCard .cardTitle > div:is(:hover, :focus) {
  color: var(--primary);
}

.productCard .priceWrapper {
  font-size: var(--fs-6);
  margin-block-end: 15px;
}

.productCard .priceWrapper .del {
  color: var(--gray_1);
}

.productCard .priceWrapper .price {
  color: var(--primary);
  margin-inline-start: 5px;
}

.productCard .btn {
  margin-inline: auto;
  padding: 12px 18px;
}

.sidebar {
  display: none;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.items {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--gray_1);
}

.image {
  width: 120px !important;
  height: 120px !important;
}

.product .gridList {
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

@media (min-width: 480px) {
  .productCard .cardBanner img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .product .gridList {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .product .gridList {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1200px) {
  .gridContainer {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
  }

  .product .gridList {
    grid-template-columns: repeat(5, 1fr);
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    min-height: 100%;
    padding: 10px 13px 30px;
    z-index: 2;
    transition: 0.25s var(--cubic-in);
    overflow: auto;
    max-height: 100%;
    gap: 8px;
    background-color: var(--greyish-white);
  }

  .categoryTitle {
    margin-bottom: 10px;
    padding: 10px 0;
  }

  .sidebarItems {
    font-size: var(--fs-5);
    font-weight: var(--fw-500);
    padding: 15px;
    border-radius: var(--radius-4);
    color: var(--dark-blue);
    transition: var(--transition-2);
    width: max-content;
  }

  .sidebarItems:is(:hover, :focus) {
    cursor: pointer;
    color: var(--primary);
  }
}
