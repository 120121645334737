.sectionTitle {
  margin-block-end: 40px;
}

.container {
  padding: var(--section-padding) 0px;
  min-height: 50vh;
}

.table {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
}

.link {
  color: #4c96ff;
  cursor: pointer;
}

.grid {
  flex: 1;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-top: 1px solid #e0e0e0;
}

.gridTitle {
  font-size: 16px;
}

.gridContainer:first-child {
  border-top: none;
}

.gridContainer > div {
  display: flex;
  justify-content: flex-end;
  padding: 10px 5px;
  border-bottom: 1px solid #e0e0e0;
}

.gridContainer > div:first-child {
  justify-content: flex-start;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  /* justify-content: space-between;
  align-items: space-between; */
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid #e0e0e0;
}

.footer > div {
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.footer > div:nth-child(2),
.footer > div:nth-child(4),
.footer > div:nth-child(6) {
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .container {
    padding: var(--section-padding) 45px;
  }
}
