.container {
  padding: var(--section-padding);
}

.title {
  margin-bottom: 20px;
}

.paragraph {
  margin-bottom: 15px;
  gap: 5px;
}

.link {
  color: var(--info);
  display: inline;
}

.sectionTitle {
  padding-top: 10px;
}
