.container {
  padding: var(--section-padding) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.emoji {
  font-size: 35px;
}

.message {
  font-size: 18px;
  font-weight: var(--fw-500);
}
