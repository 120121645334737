.facebook,
.google {
  padding: 10px;
  border: 1px solid #dadce0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  transition: all 500ms;
  color: #3c4043;
  margin-bottom: 1em;
  width: 100%;
}

.facebook-icon {
  font-size: 20px;
  color: #097ceb;
}

.facebook:is(:hover, :focus),
.google:is(:hover, :focus) {
  border-color: #d2e3fc;
  background-color: rgba(66, 133, 244, 0.04);
}

.facebook-icon {
  font-size: 20px;
  color: #097ceb;
}
