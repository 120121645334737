.service {
  background-color: var(--primary);
  margin: var(--section-padding);
  margin-right: 0;
  margin-left: 0;
}

.serviceList {
  display: grid;
  gap: 15px;
}

.itemIcon {
  background-color: var(--gray_4);
  max-width: max-content;
  padding: 20px;
  border-radius: 50%;
}

.serviceItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.itemTitle {
  color: var(--white);
}

@media (min-width: 569px) {
  .serviceList {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  .serviceList {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .serviceItem {
    gap: 20px;
  }

  .itemIcon {
    padding: 25px;
  }

  .itemTitle {
    font-size: 1.75rem;
  }
}
