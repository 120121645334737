.container {
  padding: var(--section-padding) 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-height: 50vh;
}

.title {
  font-size: 48px;
}

.subtitle {
  font-size: 24px;
}

.details {
  font-size: 18px;
}

.btn {
  border-radius: 0;
}

.btn:is(:hover, :focus) {
  background-color: var(--primary-hover);
}
