.productCard {
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
  border-radius: var(--radius-4);
  transition: 0.2s ease;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fefefe;
}

.productCard:is(:hover, :focus) {
  box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1);
}

.image {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.image:is(:hover, :focus) {
  cursor: pointer;
}

.badgeOne,
.badgeTwo {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 11px;
  transition: 0.2s ease;
  z-index: 2;
  border-radius: var(--radius-4);
  opacity: 0.9;
  padding: 5px;
  text-transform: uppercase;
}

.badgeOne {
  background-color: var(--secondary);
  color: #fff;
}

.badgeTwo {
  background-color: var(--info);
  color: #fff;
}

.btnWrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  transform: translateX(50px);
  transition: 0.2s ease;
  z-index: 2;
}

.productCard:is(:hover, :focus) .btnWrapper {
  transform: translateX(0);
}

.productBtn {
  background-color: var(--white);
  color: hsl(0, 0%, 47%);
  margin-bottom: 5px;
  border: 1px solid var(--gray_7);
  padding: 5px;
  border-radius: 5px;
}

.productBtn:is(:hover, :focus) {
  background-color: var(--primary);
  color: var(--white);
  border-color: var(--primary);
}

.content {
  padding: 15px 5px 0;
}

.cardTitle {
  font-size: var(--fs-7);
  font-weight: var(--fw-600);
  text-transform: capitalize;
  margin-bottom: 4px;
  transition: 0.2s ease;
  font-family: var(--ff-roboto) !important;
}

.cardTitle:is(:hover, :focus) {
  cursor: pointer;
}

.weightContainer {
  display: flex;
  align-items: baseline;
  margin-bottom: 6px;
  flex-direction: column;
}

.weight {
  font-size: 13px;
  font-weight: var(--fw-500);
  color: var(--silver);
  margin-bottom: 6px;
  width: 58px;
}

.stock,
.outOfStock {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: var(--fw-500);
}

.stock {
  color: var(--primary);
}

.outOfStock {
  color: var(--error);
}

.flexbox {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  flex-direction: column;
}

.priceWrapper {
  display: flex;
  gap: 5px;
  color: var(--primary);
  align-items: center;
  font-size: 14px;
  font-weight: var(--fw-700);
}

.del {
  font-size: 1rem;
  color: var(--gray_1);
  font-weight: var(--fw-500);
}

.btn {
  border-radius: 0;
  padding: 8px 13px;
  font-size: 12px;
  width: 100%;
  justify-content: center;
  border-radius: 50px;
  font-weight: var(--fw-400) !important;
  line-height: 13px;
}

.cartBtn {
  display: flex;
  border-radius: 0;
  padding: 8px 13px;
  font-size: 12px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: var(--fw-400) !important;
  background-color: var(--primary) !important;
  line-height: 13px;
  color: var(--white) !important;
  position: relative;
}

.iconBtn {
  padding: 0 !important;
  font-size: 12px !important;
  background-color: var(--white) !important;
  border-radius: 50% !important;
  padding: 5px !important;
  position: absolute !important;
  top: 3px !important;
  color: var(--primary) !important;
}

.iconBtn:is(:hover, :focus) {
  background-color: var(--white) !important;
}

.removeIcon {
  left: 3px;
}

.addIcon {
  top: 3.25px;
  right: 3px;
}

@media (min-width: 568px) {
  .weightContainer {
    flex-direction: row;
    gap: 30px;
  }

  .weight {
    margin-bottom: 0px;
  }

  .flexbox {
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 480px) {
  .image {
    width: 160px;
    height: 160px;
  }
}
