.container {
  padding: var(--section-padding) 0;
}

.cardTitle {
  font-size: 24px;
  font-weight: var(--fw-600);
  margin-bottom: 22px;
}

.gridContainer {
  background-color: var(--white);
  border-radius: 10px;
  padding: 20px;
  display: grid;
}

.title {
  font-weight: var(--fw-500);
  margin-bottom: 5px;
}

.details {
  margin-bottom: 20px;
}

.billGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
}

.billGrid > div {
  display: flex;
  justify-content: flex-start;
}

.billGrid > div:nth-child(2) {
  justify-content: flex-end !important;
}

.billGrid > div:nth-child(4) {
  justify-content: flex-end !important;
}

.billGrid > div:nth-child(6) {
  justify-content: flex-end !important;
}

.billGrid > div:last-child {
  justify-content: flex-end !important;
}

.total {
  border-top: 2px solid #cacaca;
  padding: 10px 0;
  margin-bottom: 20px;
}

.btn {
  width: 100%;
  justify-content: center;
  border-radius: 0;
}

.btn:is(:hover, :focus) {
  background-color: var(--primary-hover);
}

.price {
  font-weight: var(--fw-600);
  font-size: 20px;
}

@media (min-width: 992px) {
  .container {
    padding: var(--section-padding);
  }

  .gridContainer {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
  }

  .subtotal {
    margin-top: 40px;
  }
}
