.sliderContainer {
  overflow: hidden;
  width: 100% !important;
}

.sliderDots {
  padding-right: 2em;
}

.sliderNextArrow {
  position: absolute;
  top: 40%;
  right: 20px;
  padding: 0.5em;
  background: #edf1f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sliderPrevArrow {
  position: absolute;
  top: 40%;
  left: 20px;
  z-index: 4;
  padding: 0.5em;
  background: #edf1f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrowIcon {
  display: grid;
  place-content: center;
  font-size: 20px;
}

.disabled {
  display: none !important;
}

.imageContainer {
  width: 100%;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 576px) {
  .sliderNextArrow {
    top: 45%;
    right: 30px;
  }

  .sliderPrevArrow {
    top: 45%;
    left: 30px;
  }

  .sliderContainer {
    width: 420px;
  }
}

@media (min-width: 576px) {
  .imageContainer {
    height: 500px;
  }
}

@media (min-width: 992px) {
  .sliderNextArrow {
    top: 45%;
    right: 40px;
  }

  .sliderPrevArrow {
    top: 45%;
    left: 40px;
  }
}

@media (min-width: 1200px) {
  .imageContainer {
    height: unset;
  }

  .image {
    object-fit: cover;
  }
}
