.modal {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.subtitle {
  font-size: 14px;
  display: none;
}

.subtitle > * {
  color: #0079d3;
}

@media (min-width: 569px) {
  .subtitle {
    display: flex;
    gap: 5px;
  }
}
