.container {
  padding: var(--section-padding);
}

.grid {
  display: grid;
  gap: 50px;
  margin-bottom: 100px;
}

.imageContainer {
  width: 100%;
  height: 100%;
  padding: 50px;
  background-color: var(--white);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
  border-radius: 10px;
}

.content {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 10px;
}

.itemTitle {
  font-weight: var(--fw-400);
  font-size: 20px;
}

.itemNote {
  color: var(--orange);
}

.itemAvailability > span {
  display: inline;
}

.itemPrice {
  color: var(--primary);
  font-weight: var(--fw-700);
}

.btn {
  border-radius: 0;
  padding: 8px 13px;
  font-size: 12px;
  width: 100%;
  justify-content: center;
  border-radius: 50px;
  font-weight: var(--fw-400) !important;
  line-height: 13px;
  width: 209px;
  height: 41.53px;
}

.cartBtn {
  display: flex;
  border-radius: 0;
  padding: 8px 13px;
  font-size: 12px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: var(--fw-400) !important;
  background-color: var(--primary) !important;
  line-height: 13px;
  color: var(--white) !important;
  position: relative;
  width: 209px;
  height: 41.53px;
}

.iconBtn {
  padding: 0 !important;
  font-size: 12px !important;
  background-color: var(--white) !important;
  border-radius: 50% !important;
  padding: 5px !important;
  position: absolute !important;
  top: 4px !important;
  color: var(--primary) !important;
}

.iconBtn:is(:hover, :focus) {
  background-color: var(--white) !important;
}

.removeIcon {
  left: 3px;
}

.addIcon {
  top: 3.25px;
  right: 3px;
}

.topProductList {
  grid-template-columns: 1fr;
}

.headerContainer {
  margin-block-end: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 480px) {
  .topProductList {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 769px) {
  .topProductList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }

  .imageContainer {
    display: flex;
    justify-content: center;
  }

  .itemTitle {
    font-size: 28px;
  }

  .content {
    justify-content: center;
  }

  .itemPrice {
    font-size: 20px;
  }

  .topProductList {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .topProductList {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
