/* Aside */

.sidePanel {
  position: fixed;
  top: 0;
  right: -320px;
  background-color: var(--white);
  max-width: 320px;
  width: 100%;
  height: 100%;
  padding: 30px 0px 25px 10px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-2);
  z-index: 6;
  visibility: hidden;
  transition: 0.25s var(--cubic-in);
}

.sidePanel.active {
  visibility: visible;
  transform: translateX(-320px);
  transition: 0.5s var(--cubic-out);
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  padding: 10px 15px;
  justify-content: space-between;
}

.mycart {
  font-size: 20px;
  color: var(--black_99);
  font-weight: var(--fw-500);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.cartIcon {
  font-size: 20px;
}

.clearCart {
  color: var(--black);
  text-transform: capitalize !important;
  font-size: 14px;
  color: var(--dark-blue);
  font-weight: var(--fw-500);
}

.panelCloseBtn {
  position: absolute;
  top: -30px;
  right: 0;
  color: var(--gray_9);
  font-size: 1.5rem;
  padding: 7px;
  border-radius: var(--radius-4);
}

.panelList {
  margin-block-end: auto;
  max-height: 100%;
  overflow-y: auto;
  margin-bottom: 20px;
  flex-grow: 1;
}

.panelList::-webkit-scrollbar {
  width: 10px;
}

.panelList::-webkit-scrollbar-track {
  background-color: rgb(247, 246, 246);
  border-radius: 50px;
}

.panelList::-webkit-scrollbar-thumb {
  background: rgb(230, 228, 228);
  border-radius: 10px;
}

.panelList::-webkit-scrollbar-button {
  height: 10px;
  background: rgb(247, 246, 246);
}

.panelItem:not(:last-child) {
  border-block-end: 1px solid var(--gray_7);
}

.flexbox {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 30px;
}

.panelCard {
  display: flex;
  align-items: flex-start;
  padding: 15px;
}

.itemBanner {
  width: 22%;
}

.secondSection {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 78%;
  flex-grow: 1;
  min-width: 0;
}

.detailsSection {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 0;
}

.details {
  font-size: 1rem;
  margin-bottom: 10px;
  padding: 0 15px;
}

.itemTitle {
  color: var(--dark-blue);
  font-size: var(--fs-7);
  font-weight: var(--fw-700);
  margin-block-end: 6px;
}

.price {
  font-size: 14px;
  font-weight: var(--fw-500);
  margin-bottom: 6px;
}

.weight {
  color: #f81d22;
  display: inline;
  font-weight: var(--fw-500);
}

.panelItem .itemValue {
  color: var(--gray_1);
  font-size: var(--fs-8);
}

.panelItem .itemCloseBtn {
  margin-inline-start: auto;
  color: var(--gray_8);
  font-size: 1.78rem;
}

.panelItem .itemCloseBtn ion-icon {
  --ionicon-stroke-width: 25px;
}

.subtotalContainer {
  padding: 15px;
}

.sidePanel .subtotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.sidePanel .subtotalText {
  color: var(--dark-blue);
  font-weight: var(--fw-700);
  font-size: 14px;
}

.sidePanel .subtotalValue {
  color: var(--black_99);
  font-size: 16px;
  font-weight: var(--fw-700);
}

.divider {
  border-top: 1px dashed var(--gray_2);
  padding: 6px;
}

.panelBtn {
  background-color: var(--primary);
  color: var(--white);
  font-size: var(--fs-6);
  text-align: center;
  padding: 12px 7px;
  border-radius: var(--radius-6);
  transition: var(--transition-1);
  margin-top: 10px;
}

.panelBtn:is(:hover, :focus) {
  background-color: var(--light-green_1);
  cursor: pointer;
}

.cartBtn {
  display: flex;
  border-radius: 0;
  padding: 8px 13px;
  font-size: 12px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: var(--fw-400) !important;
  background-color: var(--primary) !important;
  line-height: 13px;
  color: var(--white) !important;
  position: relative;
  height: 32px;
  width: 140px;
}

.iconBtn {
  padding: 0 !important;
  font-size: 5px !important;
  background-color: var(--white) !important;
  border-radius: 50% !important;
  padding: 2px !important;
  position: absolute !important;
  top: 2x !important;
  color: var(--primary) !important;
}

.iconBtn:is(:hover, :focus) {
  background-color: var(--white) !important;
}

.removeIcon {
  left: 2px;
}

.addIcon {
  right: 2px;
}

.icon {
  font-size: 16px;
}
