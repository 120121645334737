.headerContainer {
  margin-block-end: 10px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewAllBtn {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 18px;
  color: var(--primary);
  font-weight: var(--fw-600);
  transition: var(--transition-2);
}

.viewAllBtn:is(:hover, :focus) {
  cursor: pointer;
  color: var(--primary-hover);
}

.topProductList > li {
  background-color: var(--greyish-white);
  padding: 30px 20px;
  text-align: center;
  outline: 2px solid transparent;
  transition: var(--transition-2);
}

.topProductList > li:is(:hover, :focus-within) {
  outline-color: var(--primary);
} /* 



.topProductList > li:is(:hover, :focus-within) .cardBanner img {
  opacity: 0.6;
} */

/* .productCard {
  background-color: var(--greyish-white);
  padding: 30px 20px;
  text-align: center;
  outline: 2px solid transparent;
  transition: var(--transition-2);
} */

/* .productCard:is(:hover, :focus-within) {
  outline-color: var(--primary);
} */

.productCard .cardBanner {
  position: relative;
  max-width: max-content;
  margin-inline: auto;
  margin-block-end: 20px;
}

.productCard .cardBanner img {
  transition: var(--transition-2);
}

/* .productCard:is(:hover, :focus-within) .cardBanner img {
  opacity: 0.6;
} */

.productCard .btnWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 10px;
}

.productCard .productBtn {
  position: relative;
  background-color: var(--primary);
  font-size: 1.42rem;
  color: var(--white);
  padding: 12px;
  border-radius: 50%;
  opacity: 0;
  transition: var(--transition-2);
}

.tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--black_99);
  border-radius: var(--radius-4);
  font-size: var(--fs-7);
  min-width: max-content;
  padding: 4px 12px;
  pointer-events: none;
  opacity: 0;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  border: 5px solid transparent;
  border-bottom-color: var(--black_99);
}

.productCard .productBtn ion-icon {
  --ionicon-stroke-width: 50px;
}

.outlineBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.topProductList > li:is(:hover, :focus-within) .productBtn {
  opacity: 1;
}

.topProductList > li .productBtn:is(:hover, :focus) {
  background-color: var(--blue-black_1);
}

.topProductList > li .productBtn:is(:hover, :focus) .tooltip {
  opacity: 1;
}

.productCard .ratingWrapper {
  margin-block-end: 10px;
}

.productCard .cardTitle {
  color: var(--dark-blue);
  font-size: var(--fs-4);
  font-weight: var(--fw-500);
  margin-block-end: 10px;
}

.productCard .cardTitle > div {
  transition: var(--transition-2);
}

.topProductList > li .cardTitle > div:is(:hover, :focus) {
  color: var(--primary);
}

.productCard .priceWrapper {
  font-size: var(--fs-6);
  margin-block-end: 15px;
}

.productCard .priceWrapper .del {
  color: var(--gray_1);
}

.productCard .priceWrapper .price {
  color: var(--primary);
  margin-inline-start: 5px;
}

.productCard .btn {
  margin-inline: auto;
  padding: 12px 18px;
}

.topProductCard .cardBanner {
  margin-block-end: 40px;
}

.topProductCard .productBtn {
  padding: 8px;
  font-size: 1.14rem;
}

.topProductCard .cardContent {
  margin-block-end: -46px;
  transition: var(--transition-2);
}

.topProductCard:is(.ratingWrapper, .cardTitle) {
  margin-block-end: 12px;
}

.topProductCard .ratingWrapper {
  transition: var(--transition-2);
}

.topProductList > li .btn,
.topProductList > li:is(:hover, :focus-within) .ratingWrapper {
  opacity: 0;
}

.topProductList > li:is(:hover, :focus-within) .cardContent {
  transform: translateY(-41px);
}

.topProductList > li:is(:hover, :focus-within) .btn {
  opacity: 1;
}

.topProductList {
  grid-template-columns: 1fr 1fr;
}

@media (min-width: 569px) {
  .topProductList {
    grid-template-columns: 1fr 1fr;
  }

  .topProductCard {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: left;
  }

  .topProductCard .cardBanner {
    margin-block-end: 0;
    margin-inline: 0;
  }

  .topProductCard .ratingWrapper {
    justify-content: flex-start;
  }

  .topProductCard .btn {
    margin-inline: 0;
  }
}

@media (min-width: 769px) {
  .topProductCard .cardBanner {
    min-width: 110px;
  }
  .topProductList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 992px) {
  .topProductList {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .topProductList {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
