.sidebar {
  display: none;
  background-color: var(--white);
}

@media (min-width: 1200px) {
  .sidebar {
    display: flex;
    padding: 10px 25px;
    border: 1px solid hsl(0, 0%, 93%);
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
    height: 611px;
  }

  .sidebarTop {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    max-height: fit-content;
  }

  .sidebarTitle {
    color: #b71c1c;
    font-size: 18px;
    letter-spacing: 0.8px;
    font-weight: var(--fw-700);
    padding-left: 10px;
    line-height: 24px;
  }
  .sidebarItem {
    transition: var(--transition-2);
    padding: 5px;
  }

  .sidebarItem:is(:hover, :focus) {
    background: rgba(153, 30, 45, 0.1);
    cursor: pointer;
  }

  .sidebarList {
    display: flex;
    flex-direction: column;
  }

  .sideItem {
    font-size: 17px;
    color: #991e2d;
    font-weight: var(--weight-500);
    height: 25px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .gridContainer {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 246px minmax(0, 1fr);
  }
}
