.container {
  position: fixed;
  right: 1.5%;
  bottom: 100px;
  background-color: #e3e3e3;
  cursor: pointer;
  width: 60px;
  padding: 8px;
  height: 60px;
  font-size: 1.42rem;
  font-weight: var(--fw-500);
  text-align: center;
  z-index: 3;
  outline: 0 !important;
  transition: all 500ms;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.actionBtn > ion-icon {
  font-size: 30px;
  color: var(--black);
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: hsla(0, 0%, 0%, 0.7);
  z-index: 4;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition-2);
}

.overlayActive {
  opacity: 1;
  pointer-events: all;
}

.actionBtn {
  position: relative;
}

.btnBadge {
  position: absolute;
  background-color: #f81d22;
  color: var(--white);
  font-size: var(--fs-9);
  font-weight: var(--fw-600);
  padding: 3px 7px;
  border-radius: 50px;
  top: -5px;
  right: -5px;
}
