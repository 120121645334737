@import url("https://fonts.googleapis.com/css2?family=Fauna+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/*----------------------------*\
 #CUSTOM PROPERTY
\*----------------------------*/

:root {
  /* colors */
  --footer-blue: hsl(206, 20%, 14%);
  --gray_1: hsl(0, 0%, 53%);
  --light-green: hsl(0, 93%, 39%);
  --dark-blue: hsl(245, 97%, 13%);
  --orange: #ff5722;
  --light-green_1: #b42233;
  --silver: #7c7c7c;
  --black_100: hsl(210, 11%, 15%);
  --blueish: hsl(222, 36%, 22%);
  --gray_2: hsl(0, 0%, 82%);
  --greyish-white: hsl(96, 36%, 95%);
  --blue-black: hsl(207, 21%, 17%);
  --blue-black_1: hsl(206, 21%, 15%);
  --black_99: hsla(0, 0%, 0%, 0.9);
  --black_98: hsla(0, 0%, 0%, 0.5);
  --black_97: hsla(0, 0%, 0%, 0.25);
  --gray_3: hsla(0, 0%, 100%, 0.5);
  --gray_4: hsla(0, 0%, 100%, 0.25);
  --gray_5: hsla(0, 0%, 100%, 0.1);
  --gray_6: hsl(0, 0%, 92%);
  --gray_7: hsl(0, 0%, 94%);
  --primary: #991e2d;
  --primary-hover: #b42233;
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --gray_8: hsl(0, 0%, 27%);
  --gray_9: hsl(0, 0%, 20%);
  --error: #ff000d;
  --warning: #ffb020;
  --info: #3366ff;
  --success: #13ce66;
  --secondary: #7fb800;

  /* typography */
  --ff-fauna: "Fauna One", serif;
  --ff-roboto: "Poppins", sans-serif;

  --fs-1: 2, 14rem;
  --fs-2: 1.71rem;
  --fs-3: 1.42rem;
  --fs-4: 1.28rem;
  --fs-5: 1.21rem;
  --fs-6: 1.07rem;
  --fs-7: 1rem;
  --fs-8: 0.92rem;
  --fs-9: 0.64rem;

  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  /* spacing */
  --section-padding: 40px;

  /* radius */
  --radius-4: 4px;
  --radius-6: 6px;

  /* shadow */
  --shadow-1: 0 12px 25px -8px hsla(0, 0%, 0%, 0.2);
  --shadow-2: -12px 0 25px -8px hsla(0, 0%, 0%, 0.2);
  --shadow-3: 12px 0 25px -8px hsla(0, 0%, 0%, 0.2);

  /* transition */
  --transition-1: 0.05s ease;
  --transition-2: 0.25s ease;
  --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);
}

/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
img,
span,
input,
label,
button,
ion-icon {
  display: block;
}

img {
  height: auto;
}

input,
button {
  background: none;
  border: none;
  font: inherit;
}

input {
  width: 100%;
}

button {
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

address {
  font-style: normal;
}

:focus-visible {
  outline-offset: 4px;
}

::selection {
  background-color: var(--black_98);
  color: var(--white);
}

html {
  font-size: 14px;
  font-family: var(--ff-roboto);
  scroll-behavior: smooth;
}

body {
  font-size: 1.14rem;
  background-color: #f2f2f2 !important;
  color: black;
}

/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container {
  padding-inline: 15px;
}

.btn {
  background-color: var(--bg-color, var(--primary));
  color: var(--color, var(--white));
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  font-size: var(--fs-, var(--fs-7));
  font-weight: var(--fw, var(--fw-500));
  padding: var(--padding, 15px 20px);
  border-radius: 50px;
  transition: var(--transition-2);
}

.btn ion-icon {
  font-size: 1.14rem;
}

.btnPrimary:is(:hover, :focus) {
  background-color: var(--light-green_1);
  cursor: pointer;
}

.outlined-btn {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  padding: 10px 15px !important;
  transition: var(--transition-2) !important;
  font-size: 1rem !important;
  text-transform: uppercase !important;
  font-weight: var(--fw-500) !important;
  border-radius: 0 !important;
}

.outlined-btn:is(:hover, :focus) {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

.section {
  padding-block: var(--section-padding);
}

.h3 {
  font-family: var(--ff-fauna);
  font-size: var(--fs-4);
}

.h2 {
  font-size: var(--fs-1);
  font-weight: var(--fw-500);
  color: var(--dark-blue);
}

.w-100 {
  width: 100%;
}

.has-scrollbar {
  display: flex;
  overflow-x: scroll;
  padding-block-end: 30px;
  margin-block-end: -30px;
  scroll-snap-type: inline mandatory;
}

.has-scrollbar::-webkit-scrollbar {
  height: 10px;
}

.has-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 15px;
}

.has-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: var(--black_97);
}

.section-subtitle {
  color: var(--primary);
  font-family: var(--ff-fauna);
  font-size: var(--fs-3);
  font-style: italic;
  margin-block-end: 10px;
}

.section-title {
  text-align: center;
  font-size: 18px;
  color: var(--black);
}

.grid-list {
  display: grid;
  gap: 10px;
  grid-auto-rows: 1fr;
}

.rating-wrapper {
  color: var(--orange);
  display: flex;
  justify-content: center;
  gap: 2px;
  font-size: 1rem;
}

@media (min-width: 569px) {
  :root {
    /* typography */
    --fs-1: 2.28rem;
  }

  /*-----------------------------------*\
  #REUSED STYLE
  \*-----------------------------------*/

  .container {
    max-width: 520px;
    margin-inline: auto;
  }
}

@media (min-width: 768px) {
  :root {
    /* typography */
    --fs-1: 2.71rem;
  }

  /*-----------------------------------*\
  #REUSED STYLE
  \*-----------------------------------*/

  .container {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  /*-----------------------------------*\
  #REUSED STYLE
  \*-----------------------------------*/

  .container {
    padding-inline: 40px;
    max-width: 1020px;
  }

  .section-title {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  /*-----------------------------------*\
  #REUSED STYLE
  \*-----------------------------------*/

  .container {
    max-width: 1200px;
  }
}

/* Slick */
.program-slider .slick-dots {
  bottom: 30px;
}

.program-slider .slick-dots li {
  width: unset;
}

@media (min-width: 992px) {
  .program-slider .slick-dots {
    bottom: 10px;
  }
}
