.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.topbar {
  background-color: var(--white);
  color: var(--primary);
  font-size: var(--fs-7);
  font-weight: var(--fw-500);
  text-align: center;
  padding-block: 10px;
  display: none;
}

.topFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.socialList {
  display: flex;
  gap: 15px;
}

.socialLink {
  transition: var(--transition-1);
  font-size: 20px;
}

.headerMain {
  background-color: var(--primary);
}

.headerUserActions {
  display: none;
}

.headerMain {
  padding: 20px 0;
}

.logo {
  display: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.mobileLogo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerSearchContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 100px;
  gap: 10px;
  flex-grow: 1;
}

.category {
  display: none;
}

.headerSearchContainer .searchField {
  font-size: var(--fs-7);
  color: var(--gray_8);
  padding: 10px 15px;
  padding-right: 20px;
  border-radius: 100px;
  /* border: 1px solid var(--gray_7); */
  background-color: var(--white);
  outline: none;
}

.searchField::-webkit-search-cancel-button {
  display: none;
}

.searchBtn {
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  color: var(--gray_8);
  font-size: 18px;
  padding: 8px 15px;
  transition: var(--transition-1);
}

.searchBtn:is(:hover, :focus) {
  color: var(--primary);
}

.mobileBottomNavigation {
  background: var(--white);
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 0;
  box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.25);
  z-index: 3;
}

.mobileBottomNavigation .actionBtn {
  position: relative;
  font-size: 26px;
  color: hsl(210, 11%, 15%);
}

.btnBadge {
  position: absolute;
  background-color: #f81d22;
  color: var(--white);
  font-size: var(--fs-9);
  font-weight: var(--fw-600);
  padding: 3px 7px;
  border-radius: 50px;
  top: -10px;
  right: -10px;
}

.dropdownPanel {
  display: none;
}

/* Actives */
.overlay {
  position: fixed;
  inset: 0;
  background-color: hsla(0, 0%, 0%, 0.7);
  z-index: 4;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition-2);
}

.overlayActive {
  opacity: 1;
  pointer-events: all;
}

.scrollActive {
  color: #333333;
  position: fixed;
  box-shadow: 0 2px 30px hsla(0, 0%, 0%, 0.1);
  width: 100%;
  z-index: 3;
}

.topActive {
  position: fixed;
  width: 100%;
  z-index: 4;
  top: 0;
}

/* Categories Nav small screen */

.navbar {
  background-color: var(--white);
  position: fixed;
  top: 0;
  left: -300px;
  max-width: 300px;
  width: 100%;
  min-height: 100%;
  padding: 30px 13px 30px;
  box-shadow: var(--shadow-3);
  z-index: 5;
  visibility: hidden;
  transition: 0.25s var(--cubic-in);
  overflow: auto;
  max-height: 100%;
}

.navbar.active {
  transform: translateX(300px);
  visibility: visible;
  transition: 0.5s var(--cubic-out);
}

.sidebarFlex {
  display: flex;
  justify-content: space-between;
}

.navCloseBtn {
  color: var(--primary);
  font-size: 2rem;
  padding: 5px;
  top: 30px;
  left: 20px;
  transition: var(--transition-1);
}

.navCloseBtn:is(:hover, :focus) {
  color: var(--primary-hover);
}

.navTitle {
  color: var(--primary);
  font-size: 20px;
  font-weight: var(--fw-700);
  padding: 17px;
  border-radius: var(--radius-4);
}

.navbarLink {
  color: var(--primary);
  font-size: 17px;
  font-weight: var(--fw-400);
  padding: 10px 17px;
  border-radius: var(--radius-4);
}

.navbarLink:is(:hover, :focus) {
  background-color: var(--gray_6);
  cursor: pointer;
}

.navbar::-webkit-scrollbar {
  width: 10px;
}

.navbar::-webkit-scrollbar-track {
  background-color: rgb(247, 246, 246);
  border-radius: 50px;
}

.navbar::-webkit-scrollbar-thumb {
  background: rgb(230, 228, 228);
  border-radius: 10px;
}

.navbar::-webkit-scrollbar-button {
  height: 10px;
  background: rgb(247, 246, 246);
}

.hamburger {
  position: relative;
  font-size: 25px;
  color: var(--white);
}

@media (min-width: 570px) {
  .logo {
    margin-bottom: 0;
  }

  .headerSearchContainer {
    min-width: 300px;
  }
}

@media (min-width: 768px) {
  .container {
    gap: 80px;
  }

  .headerSearchContainer {
    flex-grow: 1;
  }
}

@media (min-width: 992px) {
  .logo {
    display: flex;
  }

  .hamburger {
    display: none;
  }

  .headerUserActions {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .headerUserActions .actionBtn {
    position: relative;
    font-size: 25px;
    color: var(--white);
    padding: 5px;
  }
  .mobileBottomNavigation {
    display: none;
  }

  .category {
    display: flex;
    gap: 10px;
    padding: 10px 15px;
    padding-right: 0;
    flex: 60%;
    position: relative;
    font-size: 14px;
    color: var(--primary);
    align-items: center;
  }

  .category:is(:hover, :focus) {
    cursor: pointer;
  }

  .category::after {
    content: "";
    background-color: #cacaca;
    content: "";
    height: 12px;
    position: absolute;
    top: 15.6px;
    right: 0px;
    transition: all 0.5s;
    width: 1px;
  }

  .dropdownPanel {
    position: absolute;
    top: 42px;
    left: 0%;
    background-color: var(--white);
    width: 100%;
    padding: 30px;
    color: var(--primary);
    border: 1px solid var(--gray_7);
    box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0, 1);
    border-radius: 20px;
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: var(--transition-2);
    z-index: 5;
    display: initial;
  }

  .dropdownPanelList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }

  .dropdownActive {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .menuTitle {
    font-size: 14px;
  }

  .menuTitle:is(:hover, :focus) {
    cursor: pointer;
    color: var(--primary-hover);
  }

  .menuTitle > div {
    padding: 2px 5px;
    transition: var(--transition-2);
  }

  .menuTitle > div:is(:hover, :focus) {
    background: rgba(153, 30, 45, 0.1);
    border-radius: 5px;
  }

  .btnBadge {
    top: -5px;
    right: -5px;
  }
}

@media (min-width: 1200px) {
  .category {
    flex: 40%;
  }
}
